export const smoothScroll = () => {

  const scrollTriggers = document.querySelectorAll<HTMLAnchorElement>('a[href^="#"]');
  const headerHeight = document.querySelector<HTMLDivElement>('.js-header').clientHeight;

  scrollTriggers.forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();

      const scrollTarget = document.querySelector<HTMLDivElement>(trigger.getAttribute('href'));
      const scrollTargetTop = scrollTarget.getBoundingClientRect().top;
      const offsetTop = window.pageYOffset;
      const top = scrollTargetTop + offsetTop - headerHeight;
      window.scrollTo({ top: top, behavior: 'smooth' });

    });
  });
}
