import { headerMenu } from './src/header_menu';
import { toggleHeaderTransparent } from './src/toggle_header_transparent';
import { smoothScroll } from './src/smooth_scroll'
import { fadeinFunction } from './src/fadein'
import { sendContactForm } from './src/contact_form';

headerMenu();
toggleHeaderTransparent();
smoothScroll();
fadeinFunction();
sendContactForm();