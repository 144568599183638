export const headerMenu = () => {

  const hamburgerButton = document.querySelector<HTMLDivElement>('.js-hamburger-button');
  const header = document.querySelector<HTMLElement>('.js-header');
  const backdrop = document.querySelector<HTMLElement>('.js-backdrop');

  hamburgerButton.addEventListener('click', (e) => {
    header.classList.toggle('s-menu-open')
    backdrop.classList.toggle('s-menu-open')
  })
}
