export const fadeinFunction = () => {

  const fadeinTargets = document.querySelectorAll<HTMLDivElement>('.js-fadein');
  if (fadeinTargets.length === 0) return;

  window.addEventListener('scroll', () => {
    fadein();
  });

  window.addEventListener('DOMContentLoaded', () => {
    prepareToFadein();
    fadein();
  });

  const prepareToFadein = () => {
    fadeinTargets.forEach((target) => {
      target.classList.add('s-fadein-invisible');
    });
  }

  const fadein = () => {
    fadeinTargets.forEach((target) => {
      if(window.innerHeight * 0.95 > target.getBoundingClientRect().top){
        target.classList.add('s-fadein-visible');
      }
    });
  }
}