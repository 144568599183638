export const toggleHeaderTransparent = () => {

  const toggleTransparentClassWithScrollPosition = () => {

    const header = document.querySelector<HTMLElement>('.js-header');
    const headerTransparentEnd = document.querySelector<HTMLDivElement>('.js-header-transparent-end');

    if( headerTransparentEnd && headerTransparentEnd.offsetTop > window.scrollY){
      header.classList.add('transparent');
    }else{
      header.classList.remove('transparent');
    }
  }

  toggleTransparentClassWithScrollPosition()

  window.addEventListener('scroll', () => {
    toggleTransparentClassWithScrollPosition()
  });
}