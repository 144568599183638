import BootstrapModal from 'bootstrap/js/src/modal';

// メインロジック
export const sendContactForm = () => {
  const contact = document.querySelector<HTMLDivElement>(
    '[data-controller="contact"]'
  );
  if (contact === null) return;

  document.querySelector<HTMLButtonElement>('.js-submit-button').disabled =
    true;

  const confirmButton = document.querySelector<HTMLButtonElement>(
    '.js-modal-confirm-button'
  );
  confirmButton.addEventListener('click', () => {
    if (!validateForm()) return;

    confirmationModal();
  });

  const sendButton = document.querySelector<HTMLButtonElement>(
    '.js-send-form-button'
  );
  sendButton.addEventListener('click', () => {
    const submitButton =
      document.querySelector<HTMLButtonElement>('.js-submit-button');

    submitButton.disabled = false;
    submitButton.click();
    changeContentsToThanks();
    setTimeout(() => {
      window.location.href = '/';
    }, 3000);
  });
};

const validateForm = (): boolean => {
  const targetForm =
    document.querySelector<HTMLFormElement>('.js-contact-form');
  if (typeof targetForm === 'undefined') return;

  const elements = targetForm.elements;
  let valid = true;

  for (let i = 0; i < elements.length; i++) {
    if (elements[i] instanceof HTMLInputElement) {
      if (elements[i].type === 'hidden') continue;
      valid = valid && checkInput(elements[i]);
    } else if (elements[i] instanceof HTMLSelectElement) {
      // blank なしにしたので必要なし
      // console.log(elements[i]);
    } else if (elements[i] instanceof HTMLTextAreaElement) {
      // 特に必須項目なし
      // console.log(elements[i]);
    }
  }

  return valid;
};

const confirmationModal = () => {
  const targetForm =
    document.querySelector<HTMLFormElement>('.js-contact-form');
  const elements = targetForm.elements;

  const dom = [];
  for (let i = 0; i < elements.length; i++) {
    let elem = elements[i];

    if (
      ['hidden', 'button', 'submit'].includes(elem.type) ||
      elem.classList.contains('js-email-confirmation') ||
      elem.classList.contains('g-recaptcha-response')
    ) {
      continue;
    }

    let label = elem.dataset.label;
    let value = elem.value;

    if (elem instanceof HTMLSelectElement) {
      let idx = elem.selectedIndex;
      value = elem.options[idx].text;
    }
    dom.push(confirmationRow(label, value));
  }

  document.querySelector('.js-confirm-modal-body').innerHTML = dom.join('');

  const modal = new BootstrapModal(document.querySelector('.js-confirm-modal'));
  modal.show(); // 戻るは data-bs-dismiss で行ってるので hide は必要ない
};

const confirmationRow = (label, value) => {
  return `<dt>${label}</dt><dd>${value}</dd>`;
};

// 全体で使うメッセージ
const errorMessages = {
  empty: '必須の項目を入力してください。',
  format: 'Email の形式を入力してください。',
  emailPair: 'メールアドレスが一致しません。',
};

// 型別の関数
const checkInput = (input: HTMLInputElement): boolean => {
  const nextElem = input.nextElementSibling;
  if (nextElem === null) return true;

  nextElem.innerHTML = '';
  nextElem.classList.remove('d-block');

  const errors = [];
  if (!validRequired(input)) errors.push(errorMessages['empty']);

  if (input.type === 'email' && !validEmailFormat(input))
    errors.push(errorMessages['format']);

  if (!validEmailPair(input)) errors.push(errorMessages['emailPair']);

  if (errors.length > 0) {
    nextElem.innerHTML = errors.join('<br>');
    nextElem.classList.add('d-block');
    return false;
  }

  return true;
};

// バリデーション
const validRequired = (element: HTMLInputElement): boolean => {
  if (element.required && element.value === '') return false;

  return true;
};

const validEmailFormat = (element: HTMLInputElement): boolean => {
  if (!element.value.includes('@')) return false;

  return true;
};

const validEmailPair = (element: HTMLInputElement): boolean => {
  if (
    element.classList.contains('js-email-confirmation') &&
    element.value !==
      document.querySelector<HTMLInputElement>('.js-email').value
  ) {
    return false;
  }

  return true;
};

// 送信後にthanks文言を表示
const changeContentsToThanks = () => {
  const confirmContent = document.querySelector<HTMLDivElement>(
    '.js-confirm-modal-content'
  );
  const thanksContent = document.querySelector<HTMLDivElement>(
    '.js-thanks-modal-content'
  );

  confirmContent.classList.add('d-none');
  thanksContent.classList.remove('d-none');
};
